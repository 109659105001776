.about__container {
    display: grid;
    grid-template-columns: 35% 53%;
    gap: 12%;
}

.about__portrait {
    height: fit-content;
    max-height: 40rem;
    border: 1rem solid var(--color-white);
    transition: var(--transition);
}

.about__portrait:hover {
    border-width: 0;
}

.about__cards {
    margin: 3rem 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.card.about__card {
    text-align: center;
}

.about__card-icon {
    font-size: 1.3rem;
    color: var(--color-black);
    border-radius: var(--border-radius-3);
    width: fit-content;
    margin-inline: auto;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    transition: var(--transition);
}

.card.about__card:hover .about__card-icon {
    background: var(--color-primary);
    color: var(--color-white);
}

.card.about__card h5 {
    margin: 1rem 0;
}

.about__right p {
    margin-bottom: 0.8rem;
}

.about__right .btn {
    margin-top: 2.5rem;
}

/* Añadiendo el CSS para la nueva estructura */
.header {
    height: 100vh;
    display: grid;
    grid-template-columns: 5fr 4fr;
    gap: 7rem;
    align-items: center;
}

.headerLeft p {
    margin: 0.8rem 0 2.5rem;
    font-size: 1.1rem;
}

.headerCta {
    display: flex;
    gap: 1.2rem;
}

.headerRight {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card1 {
    border-radius: 0 5rem;
    overflow: hidden;
    animation: identifier 10s linear alternate infinite;
}

.card2 {
    border-radius: 5rem 0;
    overflow: hidden;
    animation: identifier 20s linear alternate infinite;
}

.card3 {
    border-radius: 5rem 0;
    overflow: hidden;
    animation: identifier 25s linear alternate infinite;
}

.card4 {
    border-radius: 0 5rem;
    overflow: hidden;
    animation: identifier 15s linear alternate infinite;
}

@keyframes identifier {
    0% {
        filter: saturate(1) opacity(1) blur(0);
    }

    20% {
        filter: saturate(0) opacity(1) blur(0);
    }

    50% {
        filter: saturate(0) opacity(0) blur(10px);
    }

    100% {
        filter: saturate(1) opacity(1) blur(0);
    }
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .about__left {
        display: none;
    }

    .about__container {
        grid-template-columns: 1fr;
    }

    .about__right h2 {
        text-align: center;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .header {
        min-height: fit-content;
        gap: 2.5rem;
        grid-template-columns: 1fr;
        padding: 7rem 0;
    }

    .headerLeft p {
        margin-bottom: 1.8rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__right .btn {
        margin-top: 2rem;
    }
}